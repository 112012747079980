@import '../../App.scss';

.about {
    width: calc(100vw - 8em);
    height: calc(100vh - 6em);
    margin: 3em 4em;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    position: relative;

    .content {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .elements {
            position: relative;
            width: 300px;
            margin-right: 130px;
            margin-left: -150px;

            p {
                margin: 0;
            }

            p:nth-child(1) {
                font-size: 2em;
                font-weight: bold;
            }

            p:nth-child(2) {
                font-family: 'Courier New', Courier, monospace;
                font-size: 4em;
            }

            p:nth-child(3) {
                @include cocogoose();
                font-size: 3em;
                transform: rotate(270deg);

                position: absolute;
                top: 30px;
                left: 150px;
            }

            p:nth-child(4) {
                font-size: 2em;
                font-weight: bold;
                width: 190px;
                text-align: right;
            }
        }

        .descriptionBox {
            margin-top: -30px;

            .rectangleBox {
                width: 100px;
                height: 80px;
                position: relative;

                .rectangle {
                    width: 40px;
                    height: 40px;

                    border: 1px solid $color-black;
                    box-sizing: border-box;
                    transform: rotate(-135deg);

                    position: absolute;
                }
            }

            .description {
                width: 350px;

                font-size: 1em;
                line-height: 22px;

                .name {
                    font-weight: bold;
                    margin-left: 5px;

                    &::after {
                        content: 'Douglas';
                    }

                    &:hover::after {
                        content: 'dxxglas';
                        color: $color-white;
                        background-color: $color-black;
                        font-style: italic;
                        padding: 2px 3px;
                    }
                }

                a {
                    p {
                        @include cocogoose();
                        color: $color-black;
                        font-size: 1.1em;
                        letter-spacing: 0.15em;

                        margin: 22px 0px;

                        &:hover {
                            animation: glitch 0.2s both;
                        }
                    }
                }
            }
        }
    }

    .titleBox {
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;

        .subtitle {
            font-size: 14px;
            margin: 0.5em 0;
        }

        .title {
            @include cocogoose();
            font-size: 1.8em;
            letter-spacing: 0.3em;

            margin: 0;
            margin-right: -0.3em;
        }
    }
}

@include mobile() {
    .about {
        width: calc(100vw - 4em);
        height: calc(100vh - 4em);
        margin: 2em;

        .content {
            .elements {
                margin: 0;
                margin-top: 230px;
                text-align: right;

                p {
                    margin: 0;
                }

                p:nth-child(1) {
                    font-size: 1em;
                    font-weight: bold;
                    margin-right: 30px;
                }

                p:nth-child(2) {
                    font-family: 'Courier New', Courier, monospace;
                    font-size: 2em;
                    margin-right: 30px;
                }

                p:nth-child(3) {
                    @include cocogoose();
                    font-size: 1.5em;
                    transform: rotate(270deg);

                    position: absolute;
                    top: 20px;
                    left: 82%;
                }

                p:nth-child(4) {
                    font-size: 1em;
                    font-weight: bold;
                    margin-right: 32px;
                    width: unset;
                }
            }

            .descriptionBox {
                margin-top: -80px;
                position: absolute;
                left: 0;

                .rectangleBox {
                    display: none;
                }

                .description {
                    width: 100%;
                }
            }
        }

        .titleBox {
            .title {
                font-size: 1.6em;
            }
        }
    }
}
