@font-face {
    font-family: 'Cocogoose';
    src: url('../assets/cocogoose.ttf');
}

$color-white: #f8f8f8;
$color-black: #1f1f1f;
$color-red: #f31310;
$color-blue: #00d5ff;

body {
    margin: 0;

    background-color: $color-white;
    color: $color-black;

    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    a {
        text-decoration: none;
    }
}

// Default Media Query
$mobile-width: 767px;
$tablet-width: 1024px;

@mixin mobile {
    @media screen and (max-width: $mobile-width) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: $tablet-width) {
        @content;
    }
}

// Cocogoose Font
@mixin cocogoose {
    font-family: 'Cocogoose', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Background Animation
.background {
    position: fixed;
    pointer-events: none;
    -webkit-animation: grain 1s steps(4) infinite;
    animation: grain 1s steps(4) infinite;
    background-image: url('../assets/grain.png');
    height: 200%;
    left: -50%;
    top: -50%;
    width: 200%;
    opacity: 0.5;

    z-index: -10;
}

@keyframes grain {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    10% {
        -webkit-transform: translate(-5%, -5%);
        transform: translate(-5%, -5%);
    }
    20% {
        -webkit-transform: translate(-10%, 5%);
        transform: translate(-10%, 5%);
    }
    30% {
        -webkit-transform: translate(5%, -10%);
        transform: translate(5%, -10%);
    }
    40% {
        -webkit-transform: translate(-5%, 15%);
        transform: translate(-5%, 15%);
    }
    50% {
        -webkit-transform: translate(-10%, 5%);
        transform: translate(-10%, 5%);
    }
    60% {
        -webkit-transform: translate(15%, 0);
        transform: translate(15%, 0);
    }
    70% {
        -webkit-transform: translate(0, 10%);
        transform: translate(0, 10%);
    }
    80% {
        -webkit-transform: translate(-15%, 0);
        transform: translate(-15%, 0);
    }
    90% {
        -webkit-transform: translate(10%, 5%);
        transform: translate(10%, 5%);
    }
    to {
        -webkit-transform: translate(5%, 0);
        transform: translate(5%, 0);
    }
}

// Glitch Animation

@keyframes glitch {
    0% {
        transform: skew(0);
    }
    25% {
        transform: skew(5deg);
        filter: invert(0.2);
    }
    75% {
        transform: skew(-5deg);
        filter: invert(0.2);
    }
    to {
        transform: skew(0);
    }
}
