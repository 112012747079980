// SCSS base
@import "../../App.scss";

.socialIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 1.25em;
    &:hover {
      animation: glitch 0.2s both;
    }
  }
}
