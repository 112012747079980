// SCSS base
@import '../../App.scss';

.home {
    width: calc(100vw - 8em);
    height: calc(100vh - 6em);
    margin: 3em 4em;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    .socialBar {
        width: 90px;

        position: absolute;
        top: 0;
        right: 0;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .titleBox {
        width: 400px;

        position: absolute;
        bottom: 12em;
        left: 4em;

        display: flex;
        justify-content: space-evenly;

        @include cocogoose();

        .rectangleBox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            position: relative;

            margin: 2em;

            .rectangle {
                width: 30px;
                height: 30px;

                border: 1px solid $color-black;
                box-sizing: border-box;
                transform: rotate(-135deg);

                position: absolute;
            }
        }

        .title {
            width: 300px;
            font-size: 1.6em;
            font-weight: normal;
            letter-spacing: 5px;
            word-wrap: break-word;
        }

        .blueElements {
            position: absolute;
            top: 30px;
            right: 58px;
            z-index: -1;

            display: flex;
            align-items: center;
            justify-content: space-between;

            .rectangleBlue {
                width: 80px;
                height: 8px;
                background-color: $color-blue;
                margin: 5px;
            }

            .squareBlue {
                width: 8px;
                height: 8px;
                background-color: $color-blue;
                margin: 5px;
            }
        }

        .redElements {
            display: flex;
            justify-content: center;
            align-items: center;

            margin-left: -20px;
            margin-top: 10px;

            .circleRed {
                width: 30px;
                height: 30px;
                border-radius: 50%;

                background-color: $color-red;
            }

            .circleOutline {
                width: 30px;
                height: 30px;
                border-radius: 50%;

                position: absolute;
                margin-top: 4px;
                margin-right: 7px;

                border: 1px solid $color-black;
            }
        }

        .blackElements {
            display: flex;
            justify-content: center;
            align-items: center;

            position: absolute;
            right: 60px;
            top: 95px;

            .squareBlack {
                width: 11px;
                height: 11px;
                background-color: $color-black;
            }

            .squareOutline {
                width: 10px;
                height: 10px;

                position: absolute;
                margin-top: -3px;
                margin-right: -3px;

                border: 1px solid $color-black;
            }
        }
    }

    .scrollIcon {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@include mobile() {
    .home {
        width: calc(100vw - 4em);
        height: calc(100vh - 4em);
        margin: 2em;

        .titleBox {
            width: 265px;
            bottom: unset;
            left: unset;
            flex-wrap: wrap;

            .rectangleBox {
                position: absolute;
                top: -100px;
                left: -10px;
            }

            .title {
                width: 232px;
                font-size: 1.2em;
            }

            .blueElements {
                top: 80px;
                right: 20px;
            }

            .redElements {
                position: absolute;
                top: -50px;
                right: 5px;
            }

            .blackElements {
                top: 120px;
                right: 80px;
            }
        }
    }
}
